@import '../styles/colors.scss';
@import '../styles/sizes.scss';

.menu {
  .workspace {
    background-color: $neutral6;

    .selectOrganizationName {
      color: white;
    }
  }
  .selectWorkspace.oppenned {
    position: absolute;
    z-index: 10000000;
    left: 0px;
    background-color: $neutral6;
    margin-top: 0px;
    width: 13vw;
  }
}

.workspace {
  padding: 10px 20px;
  border: 1px solid $grey500;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 12px;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;

  .selectContent {
    width: 100%;
  }

  .selectOrganizationName {
    font-weight: bold;
    margin-bottom: 5px;
    color: $neutral7;
  }
  .selectorganizationRole {
    color: $grey500;
  }
}

.workspace.selected {
  border: 1px solid $primary;
  padding-right: 10px;
  .buttonsSelect {
    margin-left: 10px;
  }
}

.selectWorkspace {
  padding: 15px;
  margin-top: 12px;
  border: 1px solid #eaedf5;
  box-sizing: border-box;
  /* Drop Box */

  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.1);
  border-radius: 12px;

  .user {
    display: flex;
    flex-direction: row;
    margin-left: -5px;
    color: $grey500;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #eaedf5;
  }

  .title {
    font-weight: 500;
    margin-top: 12px;
    margin-bottom: 12px;
  }
}
.selectWorkspace.noShadow {
  box-shadow: none;
}
