.plate-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.plate-description {
  color: var(--Neutral-04, #858ba4);
  font-family: Inter;
  font-size: 18px;
  font-style: italic;
  font-weight: 300;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.18px;
}

.plate-frame {
  display: flex;
  width: 100%;
  height: 60px;
  align-items: flex-start;
  gap: 10px;

  border-radius: 10px;
  border: 4px solid #000;
  background: #fff;
}

.plate-border-right {
  border-radius: 0px 6px 6px 0px;
  width: 30px;
  flex-shrink: 0;
  align-self: stretch;
  background: var(--Primary-01, #2a85ff);
}

.plate-border-left {
  border-radius: 6px 0px 0px 6px;
  width: 30px;
  flex-shrink: 0;
  align-self: stretch;
  background: var(--Primary-01, #2a85ff);
}

.plate-content {
  display: flex;
  padding: 17px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;

  color: #000;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 100% */
  letter-spacing: -0.24px;
}
