@import '../../../styles/colors.scss';
@import '../../../styles/sizes.scss';

.detail-content {
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 36px;
  flex-shrink: 0;

  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 24px 0px;

  border-radius: 8px;
  background: $neutral0;
  color: $neutral7;
}
