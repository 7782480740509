@import '../styles/colors.scss';
@import '../styles/sizes.scss';

.popup-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
}

.terminalTitle {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
}

.Btn {
  cursor: pointer;
  background: $neutral1;
  border-radius: 8px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 10px;

  /* Inside auto layout */

  flex: none;
  order: 4;
  flex-grow: 0;
  margin-left: 0px;

  .svg {
    height: 18px;
    width: 18px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 0px;
    fill: $neutral7;
    stroke: $neutral7;
  }
}

.popup-header {
  /* Auto layout */
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 20px;
}

.terminalCounter {
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;

  /* Neutral/07 */

  background: #1a1d1f;
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;

  .title1SemiBold {
    color: $neutral0;
    font-size: 12px;
  }
}

.popup-subtitle_notification {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.popup-subtitle-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 58px;
  order: 1;
  background-color: $tertiary4;
  border-radius: 8px;
  margin-right: 12px;
}

.popup-subtitle-description {
  /* Auto layout */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  order: 2;
}

.popup-subtitle-description-header {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.teminalHeaderButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
}

.popup-body-alert-title {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 20px;

  .base2.primary1 {
    color: $primary1;
    size: 14px;
  }

  .base2.neutral4 {
    color: $neutral4;
    size: 14px;
  }
}

.box_terminal_notification {
  background: $neutral1;
  padding-top: 24px;
  padding-bottom: 24px;
}

.base2.title {
  color: $neutral7;
  size: 15px;
}

.base2.time {
  color: $neutral4;
  size: 13px;
  letter-spacing: -0.01em;
}

.base2.description {
  color: $neutral4;
  size: 15px;
}

.popup-subtitle-description-content {
  height: 24px;
}
