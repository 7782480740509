.popup {
  position: relative;
  border-radius: 16px;
  background-color: var(--neutral-01);
  box-shadow: var(--depth-01);
  backdrop-filter: blur(32px);
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  text-align: left;
  font-size: 20px;
  color: var(--neutral-05);
  font-family: var(--base-2);

  position: fixed;
  top: 30vh;
  z-index: 100;
}

.large {
  top: 5%;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
}

.content {
  padding: 1rem;
}

.body {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  gap: 5px;

  box-sizing: border-box;
  max-height: 80vh; /* 90% of the viewport height */
  overflow: hidden;
  overflow-y: auto; /* allow vertical scrolling if necessary */
  width: 100%;

  /* Custom scrollbar */
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #555;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #888;
  }
}

.bottomBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  background-color: var(--neutral-00);
  box-shadow: var(--border-top-grey);
  padding: 12px 24px;
  justify-content: flex-end;
  gap: 12px;
  font-size: var(--button-2-size);
  color: var(--neutral-07);
}

@media (min-width: 768px) {
  .popup {
    left: calc(50% - 20rem);
    width: 40rem;
  }
  .large {
    left: 10%;
    width: 80%;
  }
}
