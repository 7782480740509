.iconsMaterial {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.property1default {
  position: absolute;
  top: 24px;
  left: 20px;
  border-radius: 12px;
  background-color: var(--neutral-02);
  width: 44px;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 12px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.iconsMaterial1 {
  position: relative;
  width: 22px;
  height: 22px;
  overflow: hidden;
  flex-shrink: 0;
}
.frameChild {
  position: relative;
  border-radius: 2px;
  background-color: var(--primary-01);
  width: 2px;
  height: 20px;
}
.recherche {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 24px;
  font-weight: 600;
}
.rectangleParent {
  gap: 2px;
}
.left,
.rectangleParent,
.searchInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.left {
  gap: 8px;
}
.searchInput {
  height: 20px;
}
.inputField {
  border-style: none;
  background-color: var(--neutral-02);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-3xs) 8px var(--padding-3xs) var(--padding-3xs);
  caret-color: var(--primary-01);
  color: var(--neutral-05, #333b59);
  width: 283px;
}
.inputField:focus {
  border: 0px;
  border-style: none;
}
.searchBox {
  border-radius: 8px;
  background-color: var(--neutral-02);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 0 var(--padding-3xs);
}
