// color
$second-blue: #ddebff;

$black: #1a1d1f;

@import '../../../styles/colors.scss';

.dune-icon {
  svg {
    vertical-align: middle;
    cursor: pointer;
  }

  &--primary {
    svg {
      fill: white;
    }
  }

  &--secondary {
    svg {
      fill: $neutral5;
    }
  }

  &--tertiary {
    svg {
      fill: $neutral7;
    }
  }

  &--neutral4 {
    svg {
      fill: $neutral4;
    }
  }

  &--primary-02 {
    svg {
      fill: white;
    }
  }

  &--primary-03 {
    svg {
      fill: $primary3;
    }
  }

  &--orange {
    svg {
      fill: $orange;
    }
  }

  &--transparent {
    svg {
      fill: transparent;
    }
  }

  &--quaternary {
    svg {
      fill: $black;
    }
  }

  &--white {
    svg {
      fill: $black;
    }
  }

  &--grey500 {
    svg {
      fill: $grey500;
    }
  }

  &--medium {
    svg {
      height: 36px;
      width: 36px;
    }
  }
  &--large {
    svg {
      height: 48px;
      width: 48px;
    }
  }
}
