@import '../../../styles/colors.scss';
@import '../../../styles/sizes.scss';

.detailVehicle {
  display: flex;
  flex-direction: row;
  min-height: 100vh;

  .right {
    background-color: $neutral-background;
    width: 85vw;
    padding-left: 3vw;
    padding-right: 3vw;
  }

  .detail-title {
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    padding: 16px;

    /* Neutral/00 */

    background: $neutral-background;
    border-radius: 8px;

    flex: none;
    order: 0;
    flex-grow: 0;

    .base2 {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 48px;
      /* identical to box height, or 120% */

      letter-spacing: -0.02em;
      color: #1a1d1f;
    }
  }

  .detail-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 24px;
    background: #fcfcfc;
    border-radius: 8px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 24px 0px;

    width: 100%;
  }

  .section-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .active-switch {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #fcfcfc;
    border-radius: 8px;
    padding: 16px;
    height: 24px;
    .base2 {
      padding-right: 10px;
      size: 14px;
      color: $neutral7;
    }
  }

  .left-tabs {
    margin-top: 15px;
    position: sticky;
    top: 10px;
    width: 280px;
  }
  .select-input {
    width: 100%;
  }

  .detail-form {
    width: 100%;
  }

  .detail-items-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    padding: 10px;
  }

  .detail-items-row.disabled {
    display: none;
  }

  .panelTitleText {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 24px;
    color: $neutral5;
    text-align: left;
    margin-bottom: 14px;
  }

  .checkbox_line {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    color: $neutral5;
    text-align: left;
    margin-bottom: 14px;

    .checkbox_line_title {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      height: 24px;
      color: $neutral5;
      text-align: left;
      margin-bottom: 14px;
    }

    .checkbox_line_checkbox {
      margin-right: 12px;
    }
  }

  .panelInput {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    text-align: left;
    .input-prefix {
      width: 48px;
      height: 48px;
      align-items: center;
      text-align: center;
      background-color: #e4e5e7;
      border-radius: 8px 0px 0px 8px;
    }
    input {
      width: 100%;
      height: 48px;
      align-items: center;
      background: #f4f4f4;
      border-radius: 0px 8px 8px 0px;
    }

    .input {
      width: 100%;
      height: 48px;
      align-items: center;
      background: #f4f4f4;
      border-radius: 0px 8px 8px 0px;
    }
  }

  .panelInput.disabled {
    display: none;
  }

  .detail-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .tabBtn {
    cursor: pointer;
    background: $neutral1;
    border-radius: 8px;

    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    padding: 8px 16px;

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 16px;
    border-radius: 8px;

    .base2 {
      color: $neutral7;
      flex: none;
      order: 1;
      flex-grow: 0;
      margin: 0px 4px;
    }
  }

  .tabBtnActive {
    cursor: pointer;
    background: $neutral3;
    border-radius: 8px;

    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    padding: 8px 16px;

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 16px;
    border-radius: 8px;

    .base2 {
      color: $neutral7;
      flex: none;
      order: 1;
      flex-grow: 0;
      margin: 0px 4px;
    }
  }

  .updateDate {
    text-align: right;

    /* Grey 500 */
    font-style: italic;
    color: $grey500;
  }

  .panelRow > div {
    //   width: 50%;
    flex: 50%;
  }
  .panelRow > div:nth-child(2n) > .panelTitle {
    border-left-width: 0px;
    //   width: 50%;
  }

  .vehiclesList {
    display: none;
    background-color: white;
    position: absolute;
    top: 100px;
    padding: 12px;
    width: 235px;
    border-radius: 12px;
    box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.1);

    .title {
      margin-top: 20px;
      margin-bottom: 10px;
      color: $neutral7;
    }
    .email {
      color: $grey500;
      flex-direction: row;
      display: flex;
      justify-content: space-between;
    }

    label {
      color: $grey500;
      margin-bottom: 5px;
    }

    .errorMessage {
      margin-top: 10px;
    }
    input {
      margin-top: 0px;
    }
    .eye {
      margin-top: -3px;
    }
  }

  .vehiclesList.visible {
    display: block;
  }
}
