@import '../styles/colors.scss';
@import '../styles/sizes.scss';

.menu {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  background: $neutral10;
  z-index: 1000;
  top: 0;

  select {
    background: transparent;
    border: none;
    color: $grey500;
  }
  .menu-top {
    display: flex;
    flex-direction: column;
    position: fixed;
    min-width: 90px;
    max-width: 13vw;
    background-color: $neutral10;
  }

  .selectWorkspace {
    border: none;
    overflow-y: auto;
    max-height: 48vh;
    overflow-x: hidden;
  }

  .versionNumber {
    color: $grey500;
    text-align: center;
    margin-top: 10px;
  }

  .add-voyage {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin: 0;
    padding: 0px;
    border-radius: 8px;
  }

  .add-voyage.rowSelected {
    background-color: $neutral0;
    .svg {
      fill: $neutral10;
    }
  }

  .add-voyage.rowNotSelected {
    background-color: $neutral6;
    .svg {
      fill: $neutral0;
    }
  }

  .menu-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    bottom: 85px;
    min-width: 90px;
    max-width: 13vw;
  }

  .links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    cursor: pointer;
  }

  .link {
    display: flex;
    align-items: center;
    color: $grey500;
    .caption1 {
      font-size: 13px;
    }
    .icon {
      stroke: $neutral4;
      fill: $neutral4;
    }
  }

  .link.center {
    justify-content: center;
  }

  .linkSelected {
    display: flex;
    align-items: center;
    color: $neutral1;
    .icon {
      stroke: $neutral1;
      fill: $neutral1;
    }
  }

  .rowSelected {
    background-color: $neutral6;
    border-radius: 8px;
    margin: 0px 10px;
  }

  .rowNotSelected {
    margin: 0px 10px;
  }

  .organizationName {
    margin: 17px;
    margin-bottom: 10px;
    font-weight: bold;
  }

  .block {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .userEmail {
    text-align: 'center';
    color: $grey500;
    margin: 17px;
    margin-bottom: 10px;
  }

  .account {
    text-align: 'center';
    color: $grey500;
    margin: 17px;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .linkSelected.account {
    color: $primary;
  }

  .submenu {
    margin-left: 45px;
  }

  .logoName {
    background-color: $neutral10;
    font-weight: bold;
    font-size: $h2;
    padding-left: 24px;
    padding-top: 16px;
    padding-bottom: 16px;
    cursor: pointer;
    display: flex;
    align-items: flex-start;
  }

  .svg.hidden {
    width: 16px;
    display: none;
  }
  .svg.show {
    fill: $neutral4;
    stroke: $neutral4;
  }
}

.menu-content {
  display: flex;
  flex-direction: row;

  .menu-left {
    background: $neutral10;
    min-height: 760px;
    min-width: 13vw;
  }

  .menu-right {
    min-width: 248px;
    background: $neutral4;
    padding-top: 30px;
    position: fixed;
    left: 13vw;
    height: calc(100% - 104px);
    margin-left: 10px;
    margin-top: 69px;
    border-radius: 10px;
    .link,
    .linkSelected {
      .caption1 {
        color: white;
        font-size: 20px !important;
        font-weight: bold;
      }
    }
  }
}

.menu-left.collapsed {
  min-width: 90px;
}
.menu-left.collapsed .logoName {
  padding-left: 0px !important;
  justify-content: center;
  margin-bottom: 30px;
}

.menu-left.collapsed .menu-top {
  align-items: center;
}

.menu-left.collapsed .menu-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 85px;
  min-width: 90px;
}

.menu-right.collapsed {
  left: 90px;
}

.background-menu {
  position: fixed;
  background: $neutral10;
  height: 100vh;
  min-width: 90px;
}

.background-menu.collapsed {
  min-width: 90px;
}
