@import '../../../styles/colors.scss';
@import '../../../styles/sizes.scss';

.dune-error {
  color: $primary4;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.02em;
  margin: 0;
  width: 100%;

  &-h1 {
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px; /* 120% */
    letter-spacing: -0.8px;
  }

  &-h2 {
    font-size: 20px;
    line-height: 32px;
  }
  &-h3 {
    font-size: 14px;
    line-height: 24px;
  }

  &-title1 {
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 160% */
    letter-spacing: -0.4px;
    width: 100%;
  }

  &-title2 {
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 160% */
    letter-spacing: -0.4px;
    width: auto;
  }

  &-label {
    color: $primary4;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.14px;
  }

  &-left {
    text-align: left;
  }
  &-center {
    text-align: center;
  }
}
