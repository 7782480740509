@import '../../../styles/colors.scss';

.dune-paragraphInput {
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: flex-end;
  align-self: stretch;
  padding: 12px;
  gap: 10px;

  border-radius: 8px;
  border-style: none;
  background: $neutral2;
}

.dune-content-paragraphInput {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  width: 100%;
}

.dune-title-paragraphInput {
  height: 28px;
}

.dune-input-error {
  color: $primary4;
  margin: 5px 0 0 15px;
}
