@import '../../../styles/colors.scss';

.clickable {
  cursor: pointer;
}

.input-like {
  font-family: Inter;
  display: flex;
  padding: 10px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: var(--border-radius, 8px);
  background: $neutral2;

  color: $neutral5;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.14px;

  border: 1px solid #e3e7f2;
  font-family: Inter;
  padding: 10px 20px;
  background-color: #f4f4f4;
  color: #333b59;
  width: 100%;
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  color: $neutral5;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.14px;
  border-radius: var(--border-radius, 8px);
  background: $neutral2;
  border: none;
}

.edit-icon {
  margin-right: 8px;
}
