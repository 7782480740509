@import '../../../styles/colors.scss';
@import '../../../styles/sizes.scss';

.dune-title {
  color: $neutral7;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.02em;
  margin: 0;
  width: 100%;

  display: flex;
  padding: 2px 0px;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  &-h1 {
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px; /* 120% */
    letter-spacing: -0.8px;
  }

  &-h3 {
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 160% */
    letter-spacing: -0.48px;
  }

  &-title1 {
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 160% */
    letter-spacing: -0.4px;
    width: 100%;
  }

  &-title2 {
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 160% */
    letter-spacing: -0.4px;
    width: auto;
  }

  &-label {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.14px;
  }

  &-left {
    text-align: left;
  }
  &-right {
    text-align: right;
  }
  &-center {
    text-align: center;
  }

  &-full {
    width: 100%;
  }
  &-auto {
    width: auto;
  }
}

.breadcrumb-link {
  color: $neutral7;
  cursor: pointer;
}

.breadcrumb-link:hover {
  text-decoration: underline;
}
