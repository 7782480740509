.tabButton {
  cursor: pointer;
  color: $primary;

  padding: 10px 20px;
  font-size: 18px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.tabButton.selected {
  font-weight: bold;
  background-color: white;
}

.transparent-tabs .tab {
  background-color: transparent;
}
