@import '../../../styles/colors.scss';
@import '../../../styles/sizes.scss';

.dune-input {
  font-family: Inter;
  display: flex;
  padding: 10px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: var(--border-radius, 8px);
  background: $neutral2;

  color: $neutral5;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.14px;

  border: 1px solid #e3e7f2;
  font-family: Inter;
  padding: 10px 20px;
  background-color: #f4f4f4;
  color: #333b59;
  width: 100%;

  &-prefix {
    border: 1px solid #e3e7f2;
    align-items: center;
    background-color: #e4e5e7;
    color: #333b59;
    border-radius: 8px 0 0 8px;
    text-align: center;
    padding: 14px 20px;
  }
  &-with-prefix {
    border-radius: 0 8px 8px 0;
  }
  &-suffix {
    border: 1px solid #e3e7f2;
    align-items: center;
    background-color: #e4e5e7;
    color: #333b59;
    border-radius: 0 8px 8px 0;
    text-align: center;
    padding: 14px 20px;
  }
  &-with-suffix {
    border-radius: 8px 0 0 8px;
  }
  &-box {
    border-radius: 8px;
    align-items: center;
    display: flex;
    flex-direction: row;
    text-align: left;
    width: 100%;

    &-in-row {
      display: flex;
      align-items: center;
      gap: 5px;
      align-self: stretch;
    }
  }
  &-in-row {
    display: flex;
    padding: 10px;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    color: $neutral5;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.14px;
    border-radius: var(--border-radius, 8px);
    background: $neutral2;
    border: none;
  }
  &-suffix-in-row {
    display: flex;
    align-items: center;
    gap: 5px;
    align-self: stretch;
    background: transparent;

    color: $neutral5;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.14px;

    border: none;
    padding: 0%;
  }
  &-prefix-in-row {
    display: flex;
    align-items: center;
    gap: 5px;
    align-self: stretch;
    background: transparent;

    color: $neutral5;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.14px;

    border: none;
    padding: 0%;
  }

  &-disabled {
    color: $neutral5;
    background: transparent;
  }
}

.dune-content-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  width: 100%;

  &-in-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  &-fit {
    width: min-content;
    flex: auto;
  }
}

.dune-label {
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.14px;

  &-icon {
    margin-left: 2px;
    margin-bottom: 1px;
  }
}

.dune-input-error {
  color: #ff4f40;
  margin: 5px 0 0 15px;
}
