@import '../../../styles/colors.scss';

.onStyle {
  color: $neutral3;
  text-align: center;
  padding-top: 1px;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 133.333% */
  letter-spacing: -0.12px;
}

.offStyle {
  color: $neutral5;
  text-align: center;
  padding-top: 2px;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 133.333% */
  letter-spacing: -0.12px;
}
