@import '../../styles/colors.scss';

.table-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  align-content: center;
  width: 100%;
  padding: 5px 0px;

  .table-header-column {
    text-align: left;
    &--text {
      color: $neutral4;
      font-size: 12px;
    }

    &--box {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
}

.table-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  width: 100%;
  padding-top: 10px;
  border-top-style: none;
  border-top-width: 1px;
  border-top-color: rgba(0, 0, 0, 0.12);
  background-color: var(--neutral-00);
  box-shadow: var(--border-bottom-grey);
  padding: var(--padding-3xs) var(--padding-xs);
  box-sizing: border-box;
  text-align: right;
  color: var(--neutral-05);

  &-padded {
    padding-right: 72px;
  }
}

.table-row-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  width: 100%;

  &-right {
    justify-content: flex-end;
    gap: 6px;
    padding-right: 15px;
  }
  &-left {
    justify-content: flex-start;
    gap: 6px;
    padding-left: 15px;
  }
}

.table-row-column {
  &--text-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    padding-left: 32px;

    &--right {
      align-items: flex-end;
    }
  }

  &--text-main {
    color: #333b59; /*neutral5*/

    /* Body 1 */
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 123.077% */
    letter-spacing: -0.13px;
  }

  &--text-sub {
    color: #858ba4; /*neutral4*/

    /* Caption 2 (Medium) */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px; /* 100% */
    letter-spacing: -0.12px;
  }

  &--toggle-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  &--icon-container {
    cursor: pointer;
  }

  &--input-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
}

.clickable {
  cursor: pointer;
}
