.form-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  &-right {
    justify-content: flex-end;
  }
  &-left {
    justify-content: flex-start;
  }
  &-align-center {
    align-items: center;
  }
  &-column {
    flex-direction: column;
    gap: 10px;
  }
}
