@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;600&display=swap');

/* test locofy */
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --body-1: Inter;

  /* font sizes */
  --body-1-size: 13px;
  --font-size-sm: 14px;
  --h1-size: 40px;
  --caption-1-size: 13px;
  --caption-2-medium-size: 12px;
  --base-1-semibold-size: 15px;
  --base-2-size: 14px;

  /* Colors */
  --neutral-background: #f7f5f2;
  --neutral-00: #fff;
  --neutral-01: #fcfcfc;
  --neutral-02: #f4f4f4;
  --neutral-03: #efefef;
  --neutral-04: #858ba4;
  --neutral-05: #333b59;
  --neutral-07: #1a1f2c;
  --primary-01: #2a85ff;
  --secondary-03: #b1e5fc;
  --tertiary-01: #ddebff;
  --tertiary-03: #e6f5e6;
  --tertiary-04: #fde8df;
  --shades-03: rgba(91, 99, 132, 0.4);
  --color-darkslategray-100: #272e44;
  --color-black: #000;

  /* Gaps */
  --gap-xl: 20px;
  --gap-3xs: 10px;
  --gap-5xs: 8px;
  --gap-7xs: 6px;
  --gap-8xs: 5px;
  --gap-9xs: 4px;
  --gap-base: 16px;
  --gap-mini: 15px;

  /* Paddings */
  --padding-xl: 20px;
  --padding-5xl: 24px;
  --padding-31xl: 50px;
  --padding-xs: 12px;
  --padding-3xs: 10px;
  --padding-5xs: 8px;
  --padding-11xs: 2px;
  --padding-base: 16px;

  /* border radiuses */
  --br-5xl: 24px;
  --br-13xl: 32px;
  --br-61xl: 80px;
  --br-5xs: 8px;
  --br-7xs: 6px;
  --br-9xs: 4px;

  /* Effects */
  --border-bottom-grey: 0px -1px 0px #efefef inset;
}
