@import '../styles/colors.scss';
@import '../styles/sizes.scss';

.detailVoyage {
  display: flex;
  flex-direction: row;
  min-height: 100vh;

  .right {
    background-color: $neutral-background;
    width: 85vw;
    padding-left: 3vw;
    padding-right: 3vw;
  }

  .detail-title {
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    padding: 16px;

    /* Neutral/00 */

    background: $neutral-background;
    border-radius: 8px;

    flex: none;
    order: 0;
    flex-grow: 0;

    .base2 {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 48px;
      /* identical to box height, or 120% */

      letter-spacing: -0.02em;
      color: #1a1d1f;
    }
  }

  .detail-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 24px;
    background: #fcfcfc;
    border-radius: 8px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 24px 0px;
  }

  .section-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .active-switch {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #fcfcfc;
    border-radius: 8px;
    padding: 16px;
    height: 24px;
    .base2 {
      padding-right: 10px;
      size: 14px;
      color: $neutral7;
    }
  }

  .section_title_button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #fcfcfc;
    border-radius: 8px;
    height: 24px;
    .base2 {
      padding-right: 10px;
      size: 14px;
      color: $neutral1;
    }
  }

  .left-tabs {
    margin-top: 15px;
    position: sticky;
    top: 10px;
    width: 280px;
  }

  .detail-form {
    width: 100%;
  }

  .detail-items-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    padding: 10px;
  }

  .detail-items-row.checkbox_div {
    height: 96px;
  }

  .panelTitleText {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 24px;
    color: $neutral5;
    text-align: left;
    margin-bottom: 14px;
  }

  .divClickable {
    cursor: pointer;
  }

  .checkbox_line {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 24px;
    color: $neutral5;
    text-align: left;
    margin-bottom: 14px;

    .checkbox_line_title {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      height: 24px;
      color: $neutral5;
      text-align: left;
      margin-bottom: 14px;
    }

    .checkbox_line_checkbox {
      margin-right: 12px;
    }
  }

  .panelInput {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    text-align: left;
    .input-prefix {
      width: 48px;
      height: 48px;
      align-items: center;
      text-align: center;
      background-color: #e4e5e7;
      border-radius: 8px 0px 0px 8px;
    }
    input {
      width: 100%;
      height: 48px;
      align-items: center;
      background: #f4f4f4;
      border-radius: 0px 8px 8px 0px;
    }

    .input {
      width: 100%;
      height: 48px;
      align-items: center;
      background: #f4f4f4;
      border-radius: 0px 8px 8px 0px;
    }
  }

  .select-input {
    width: 100%;
  }

  .panelInputTextArea {
    display: flex;
    flex-direction: row;
    width: 100%;
    text-align: left;
    textarea {
      width: 100%;
      height: 56px;
      align-items: center;
      border-radius: 8px;
      border: 0px;
      background: $neutral2;
      margin: 0px 0px;
    }
  }

  .detail-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .tabBtn {
    cursor: pointer;
    background: $neutral1;
    border-radius: 8px;

    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    padding: 8px 16px;

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 16px;
    border-radius: 8px;

    .base2 {
      color: $neutral7;
      flex: none;
      order: 1;
      flex-grow: 0;
      margin: 0px 4px;
    }
  }

  .tabBtnActive {
    cursor: pointer;
    background: $neutral3;
    border-radius: 8px;

    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    padding: 8px 16px;

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 16px;
    border-radius: 8px;

    .base2 {
      color: $neutral7;
      flex: none;
      order: 1;
      flex-grow: 0;
      margin: 0px 4px;
    }
  }

  .updateDate {
    text-align: right;

    /* Grey 500 */
    font-style: italic;
    color: $grey500;
  }

  .panelRow > div {
    flex: 50%;
  }
  .panelRow > div:nth-child(2n) > .panelTitle {
    border-left-width: 0px;
  }

  .zonesList {
    display: none;
    background-color: white;
    position: absolute;
    top: 100px;
    padding: 12px;
    width: 235px;
    border-radius: 12px;
    box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.1);

    .title {
      margin-top: 20px;
      margin-bottom: 10px;
      color: $neutral7;
    }
    .email {
      color: $grey500;
      flex-direction: row;
      display: flex;
      justify-content: space-between;
    }

    label {
      color: $grey500;
      margin-bottom: 5px;
    }

    .errorMessage {
      margin-top: 10px;
    }
    input {
      margin-top: 0px;
    }
    .eye {
      margin-top: -3px;
    }
  }

  .vehiclesList.visible {
    display: block;
  }

  .produitList {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
  }

  .productlist-Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    width: 100%;
    box-shadow: inset 0px -1px 0px $neutral3;
    margin-top: 12px;
    padding: 5px 0px;

    .productlist-HeaderItem {
      text-align: left;
      .caption2medium {
        color: $neutral4;
        font-size: 14px;
      }
    }
  }

  .productlist-HeaderItem.favori {
    width: 5%;
  }

  .productlist-HeaderItem.label {
    width: 29%;
  }

  .productlist-HeaderItem.unite {
    width: 10%;
  }

  .productlist-HeaderItem.poids {
    width: 17%;
  }

  .productlist-HeaderItem.qtep {
    width: 17%;
  }

  .productlist-HeaderItem.qted {
    width: 17%;
  }

  .productlist-HeaderItem.delete {
    width: 5%;
  }

  .productlist-Item.favori {
    width: 5%;
    .svg {
      margin-left: 8px;
      fill: $neutral4;
      stroke: $neutral4;
      width: 15px;
      height: 15px;
      cursor: pointer;
    }
  }

  .productlist-Item.label {
    width: 29%;
  }

  .productlist-Item.unite {
    width: 10%;
  }

  .productlist-Item.poids {
    width: 17%;
  }

  .productlist-Item.qtep {
    width: 17%;
  }

  .productlist-Item.qted {
    width: 17%;
  }

  .productlist-Item.delete {
    width: 5%;
    .svg {
      margin-left: 8px;
      fill: $neutral6;
      stroke: $neutral6;
      width: 15px;
      height: 15px;
      cursor: pointer;
    }
  }

  .productlist-Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    width: 100%;
    box-shadow: inset 0px -1px 0px $neutral3;

    .productlist-Item {
      text-align: left;
      padding: 10px 0px;
      .caption1 {
        color: $neutral5;
        font-size: 16px;
      }

      .svg {
        margin-left: 8px;
        fill: $neutral4;
        stroke: $neutral4;
        width: 15px;
        height: 15px;
      }

      .caption2bold {
        size: 16px;
        color: $neutral6;
      }
    }
  }

  .produitList.visible {
    display: block;
  }

  .fileList {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
  }

  .filelist-Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    width: 100%;
    box-shadow: inset 0px -1px 0px $neutral3;
    margin-top: 12px;
    padding: 5px 0px;

    .filelist-HeaderItem {
      text-align: left;
      .caption2medium {
        color: $neutral4;
        font-size: 14px;
      }
    }
  }

  .filelist-HeaderItem.arrow {
    width: 5%;
  }

  .filelist-HeaderItem.label {
    width: 80%;
  }

  .filelist-HeaderItem.download {
    // width: 5%;
  }

  .filelist-HeaderItem.print {
    width: 5%;
  }

  .filelist-HeaderItem.delete {
    // width: 5%;
  }

  .filelist-Item.arrow {
    width: 5%;
    .svg {
      margin-left: 8px;
      fill: $neutral6;
      stroke: $neutral6;
      width: 15px;
      height: 15px;
      cursor: pointer;
    }
  }

  .filelist-Item.label {
    width: 80%;
  }

  .filelist-Item.download {
    // width: 5%;
    .svg {
      margin-left: 8px;
      fill: $neutral4;
      stroke: $neutral4;
      width: 15px;
      height: 15px;
      cursor: pointer;
    }
  }

  .filelist-Item.print {
    width: 5%;
    .svg {
      margin-left: 8px;
      fill: $neutral4;
      stroke: $neutral4;
      width: 15px;
      height: 15px;
      cursor: pointer;
    }
  }

  .filelist-Item.delete {
    // width: 5%;
    .svg {
      margin-left: 8px;
      fill: $neutral4;
      stroke: $neutral4;
      width: 15px;
      height: 15px;
      cursor: pointer;
    }
  }

  .filelist-Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    width: 100%;
    box-shadow: inset 0px -1px 0px $neutral3;

    .filelist-Item {
      text-align: left;
      .caption1 {
        color: $neutral6;
        font-size: 16px;
      }

      .svg {
        margin-left: 8px;
        fill: $neutral4;
        stroke: $neutral4;
        width: 15px;
        height: 15px;
      }

      .caption2bold {
        size: 16px;
        color: $neutral6;
      }
    }
  }

  .fileList.visible {
    display: block;
  }

  .bottomBar {
    display: flex;
    flex-direction: row;
    background-color: $neutral0;
    justify-content: space-between;
    align-items: center;
    padding: 16px 40px;
    backdrop-filter: blur(16px);
  }

  .bottomBar__left {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 8px 10px 10px;
    background: #f4f4f4;
    border-radius: 12px;

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 10px;

    .svgSearch {
      width: 24px;
      height: 24px;
      fill: $neutral4;
      border: 1px solid $neutral4;
    }
    .searchInput {
      height: 24px;
      color: neutral4;
    }
  }

  .bottomBar {
    display: flex;
    flex-direction: row;
    background-color: $neutral0;
    justify-content: space-between;
    align-items: center;
    padding: 16px 40px;
    backdrop-filter: blur(16px);
  }

  .bottomBar__left {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 8px 10px 10px;
    background: #f4f4f4;
    border-radius: 12px;

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 10px;

    .svgSearch {
      width: 24px;
      height: 24px;
      fill: $neutral4;
      border: 1px solid $neutral4;
    }
    .searchInput {
      height: 24px;
      color: neutral4;
    }
  }

  .bottomBar__right {
    align-items: center;
    flex-grow: 2;
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
  }

  .openLink {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;

    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 16px;

    cursor: pointer;
    background: $neutral1;
    border: 1px solid $neutral3;
    box-sizing: border-box;
    border-radius: 8px;

    .base2 {
      color: $neutral7;
      flex: none;
      order: 1;
      flex-grow: 0;
      margin: 0px 4px;
    }
  }

  .OpenBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;

    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 10px;

    cursor: pointer;
    background: $tertiary1;
    border: 1px solid $tertiary1;
    box-sizing: border-box;
    border-radius: 8px;

    .base2 {
      color: $primary1;
      flex: none;
      order: 1;
      flex-grow: 0;
      margin: 0px 4px;
    }
  }

  .svg {
    height: 18px;
    width: 18px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 0px;
    fill: $neutral7;
    stroke: $neutral7;
  }
}
