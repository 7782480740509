@import '../styles/colors.scss';
@import '../styles/sizes.scss';

.popup_barrier {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 24px 24px;
  gap: 24px;

  position: sticky;
  width: 320px;
  height: 99vh;
  top: 0px;

  /* Neutral/01 */
  background: $neutral1;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  // margin: 0px 20px;

  overflow-y: scroll;
  overflow-x: hidden;
}

.popup-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 20px;
}

.terminalTitle {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
}

.Btn {
  cursor: pointer;
  background: $neutral1;
  border-radius: 8px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;

  /* Inside auto layout */

  flex: none;
  order: 4;
  flex-grow: 0;
  margin-left: 12px;

  .svgReduce {
    height: 18px;
    width: 18px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 4px;
    fill: $neutral7;
    stroke: $neutral7;
  }
}

.popup-header {
  /* Auto layout */
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 20px;
}

.terminalCounter {
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;

  /* Neutral/07 */

  background: #1a1d1f;
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;

  .title1SemiBold {
    color: $neutral0;
    font-size: 12px;
  }
}

.popup-subtitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 12px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 20px;
}

.box_terminal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;

  position: static;
  width: 272px;
  height: 108px;
  left: 0px;
  top: 0px;

  /* Tertiary/03 */
  background: $tertiary3;
  border-radius: 8px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 12px 0px;
}

.btn_Close {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;

  width: 116px;
  height: 40px;

  /* Neutral/00 */
  background: $neutral0;
  border-radius: 6px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 0px 8px;

  .base2 {
    color: $neutral7;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 4px;
  }
}

.btn_Open {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;

  width: 116px;
  height: 40px;

  /* Primary/03 */
  background: $primary3;
  border-radius: 6px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 1;
  margin: 0px 8px;

  .base2 {
    color: $neutral7;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 4px;
  }
}
