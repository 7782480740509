// Neutral

// Shades

// Color

// Secondary

// Tertiary

$primary: #2a85ff;
$primary50: #2052f54d;

$error: #c0392b;

$green: #80cbc4;
$white: #ffffff;

$white50: rgba(255, 255, 255, 0.5);
$grey900: #010f4b;
$orange: #f26f37;

$grey6: #f2f2f2;
$grey500: #90a0b7;
$grey50010: #90a0b71a;
$grey400: #e3e7f2;
$grey300: #f5f6f9;
$grey100: #fdfdff;

$grey0: #262628;
$grey040: rgba(38, 38, 40, 0.4);
$iconBlack: #231f20;

$black900: #1a202c;
$black500: rgba(0, 0, 0, 0.5);
$black300: rgba(0, 0, 0, 0.3);
$black100: rgba(0, 0, 0, 0.1);
$black50: rgba(0, 0, 0, 0.05);
$black40: rgba(0, 0, 0, 0.04);

$green500: #2bc99b;
$green50010: #80cbc41a;

$green700: #2bc99b;

//PRIMARY
$primary1: #2a85ff;
$primary2: #565bcf;
$primary3: #65c366;
$primary4: #ff4f40;
$primary5: #f5bb00;

//SECONDARY
$secondary1: #95c2ff;
$secondary2: #aaade7;
$secondary3: #b2e1b3;
$secondary4: #f9b79b;
$secondary5: #fadd80;

//TERTIARY
$tertiary1: #ddebff;
$tertiary2: #e4e5f7;
$tertiary3: #e6f5e6;
$tertiary4: #fde8df;
$tertiary5: #fdf4d6;

//NEUTRAL
$neutral_background: #f7f5f2;
$neutral0: #ffffff;
$neutral1: #fcfcfc;
$neutral2: #f4f4f4;
$neutral3: #efefef;
$neutral4: #858ba4;
$neutral5: #333b59;
$neutral6: #272e44;
$neutral7: #1a1f2c;
$neutral8: #11141e;
$neutral10: #0b1828;

//SHADES
$shade1: #8087a5;
$shade2: #404b78;
$shade3: #5b6384;
$shade4: #33383f;
