@import '../styles/colors.scss';
@import '../styles/sizes.scss';

.versionBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.versionTitle {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.versionDescription {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .versionParagraph {
    margin: 0;
  }
}
