.iconsMaterial {
  position: relative;
  width: 22px;
  height: 22px;
  overflow: hidden;
  flex-shrink: 0;
}
.frameChild {
  position: relative;
  border-radius: var(--br-11xs);
  background-color: var(--primary-01);
  width: 2px;
  height: 20px;
}
.rectangleParent {
  gap: var(--gap-11xs);
}
.left,
.rectangleParent,
.searchInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.left {
  gap: var(--gap-5xs);
}
.searchInput {
  width: 344px;
  height: 20px;
}
.searchBox {
  border-radius: var(--br-5xs);
  background-color: var(--neutral-02);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-3xs) var(--padding-5xs) var(--padding-3xs) var(--padding-3xs);
  align-items: flex-start;
  justify-content: center;
}
.rechercheAvance {
  position: relative;
  text-decoration: underline;
  letter-spacing: -0.01em;
  line-height: 24px;
  font-weight: 600;
  color: var(--primary-01);
}
.searchBoxParent {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-mini);
  padding: 15px;
}
.iconsMaterial1 {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.button {
  border-radius: var(--br-5xs);
  background-color: var(--primary-01);
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-base);
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
}
.buttonWrapper,
.property1default {
  display: flex;
  justify-content: flex-start;
  padding: 15px;
}
.buttonWrapper {
  flex-direction: column;
  align-items: flex-end;
  font-size: var(--base-2-size);
  color: var(--neutral-01);
}
.property1default {
  border-radius: var(--br-5xs);
  background-color: var(--neutral-01);
  flex-direction: row;
  padding: var(--padding-mini);
  box-sizing: border-box;
  align-items: flex-start;
  gap: var(--gap-5xs);
}
.title {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 24px;
  font-weight: 600;
}
.button2,
.property1designActuelle {
  border-radius: var(--br-5xs);
  background-color: var(--neutral-01);
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  justify-content: center;
}
.button2 {
  border: 1px solid var(--neutral-03);
  height: 44px;
  padding: var(--padding-5xs) var(--padding-base);
  align-items: center;
  gap: 10px;
  font-size: var(--base-2-size);
  color: var(--neutral-05);
}
.property1designActuelle {
  padding: var(--padding-mini);
  align-items: center;
  gap: var(--gap-5xs);
}
.property1designActuelleTitleOnly {
  padding: 24px;
  align-items: center;
  gap: 8px;
}
.recherche {
  margin-top: 20px;
  border-radius: 5px;
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  font-size: var(--base-1-semibold-size);
  color: var(--shades-03);
  font-family: var(--base-2);
}
