@import '../../styles/colors.scss';
@import '../../styles/tab.scss';

.filters {
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  margin-top: 20px;
  border-radius: 8px;
}
.filtersContent {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.filtersAction {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.filter {
  flex: 1 40%;
}

.left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
