@import '../styles/colors.scss';
@import '../styles/sizes.scss';

.controllableDevice {
  background-color: white;
  color: black;
  width: 250px;
  height: 300px;
  margin: 30px;
  border-radius: 8px;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.getInfoButton {
  cursor: pointer;
  background: $tertiary1;
  border-radius: 8px;

  justify-content: center;
  align-items: center;
  padding: 6px 12px;

  .base2 {
    color: $primary1;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 4px;
  }
}
