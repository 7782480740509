@import '../../../styles/colors.scss';
@import '../../../styles/sizes.scss';

// color
$second-blue: #ddebff;

$black: #1a1d1f;

.dune-button {
  border: 0;
  border-radius: 8px;
  cursor: pointer;
  display: inline-block;

  .base2 {
    color: inherit;
  }
  button {
    width: 100%;
    border: none;
    font-weight: 700;
    padding: 0;
    cursor: pointer;
  }

  svg {
    vertical-align: middle;
  }

  &-label {
    margin: 0px 4px;
  }

  &--primary {
    button {
      background-color: $primary1;
      color: white;
      svg {
        fill: white;
      }
    }
    background-color: $primary1;
    border-color: $primary1;
  }

  &--secondary {
    button {
      svg {
        fill: $neutral5;
      }
      color: $neutral5;
      background-color: $neutral1;
    }
    background-color: $neutral1;
    border-color: $neutral1;
    border: 1px solid $neutral3;
  }

  &--tertiary {
    button {
      svg {
        fill: $neutral7;
      }
      color: $neutral7;
      background-color: transparent;
    }
    background-color: transparent;
    border-color: transparent;
    border: #efefef 1px solid;
  }

  &--primary-02 {
    button {
      color: white;
      svg {
        fill: white;
      }
      background-color: #565bcf;
    }

    background-color: #565bcf;
    border-color: #565bcf;
  }

  &--primary-03 {
    button {
      color: white;
      svg {
        fill: white;
      }
      background-color: #65c366;
    }

    background-color: #65c366;
    border-color: #65c366;
  }

  &--primary-04 {
    button {
      color: white;
      svg {
        fill: white;
      }
      background-color: $primary4;
    }

    background-color: $primary4;
    border-color: $primary4;
  }

  &--quaternary {
    button {
      color: $neutral5;
      svg {
        fill: $black;
      }
      background-color: $grey6;
    }
    background-color: $grey6;
    border-color: $grey6;
  }

  &--white {
    border: #efefef 1px solid;
    button {
      color: $neutral5;
      svg {
        fill: $black;
      }
      background-color: white;
    }
    background-color: white;
    border-color: white;
  }

  &--disabled {
    border: $neutral4 1px solid;
    button {
      background-color: $neutral4;
      color: $neutral0;
      svg {
        fill: $neutral0;
      }
    }
    background-color: $neutral4;
  }

  &--wide {
    display: flex;
    padding: 10px 0px;
    button {
      justify-content: center;
      align-items: center;
      gap: 16px;
      flex: 1 0 0;
      svg {
        height: 24px;
        width: 24px;
      }
      width: 100%;
    }
    width: 100%;
  }

  &--widelarge {
    display: flex;
    padding: 10px 20px;
    button {
      font-size: 14px;
      line-height: 24px;
      font-style: normal;
      font-weight: 600;
      justify-content: center;
      align-items: center;
      gap: 16px;
      flex: 1 0 0;
      svg {
        height: 24px;
        width: 24px;
      }
      width: 100%;
    }
    width: 100%;
    height: auto;
  }

  &--widethin {
    display: flex;
    button {
      justify-content: center;
      align-items: center;
      flex: 1 0 0;
      width: 100%;
    }
    width: 100%;
  }

  &--large {
    button {
      font-size: 14px;
      line-height: 24px;
      font-style: normal;
      font-weight: 600;
      svg {
        height: 24px;
        width: 24px;
      }
    }

    padding: 10px 20px;

    height: auto;
  }
  &--medium {
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      display: flex;
      svg {
        height: 22px;
        width: 22px;
      }
      justify-content: center;
      align-items: center;
      gap: 8px;
    }
    padding: 8px 16px;
  }
  &--small {
    font-size: 10px;
    line-height: 20px;
    padding: 5px 10px;
    height: fit-content;
    svg {
      height: 18px;
      width: 18px;
    }
  }

  &--prefix {
    border-radius: 8px 0 0 8px;
    padding: 10px 20px;
    border-width: 1px;
    border-style: solid;
  }
  &--suffix {
    border-radius: 0 8px 8px 0;
    padding: 10px 20px;
    border-width: 1px;
    border-style: solid;
  }
}

.dropdown {
  position: relative;
}

.menu-dropdown {
  position: absolute;

  top: 40px;
  left: 0;
  z-index: 2;
  width: 200px;
  list-style-type: none;
  margin: 5px 0;
  padding: 0;
  border-radius: 8px;

  li:first-child {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  li:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border: none;
  }

  li {
    margin: 0;
    padding: 5px 8px;
    background-color: white;
    color: #1a1d1f;
    border-bottom: 1px lightgray solid;

    button {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: left;
      background: none;
      border: none;
      padding: 5px;
      margin: 0;
      font: inherit;
      cursor: pointer;
    }
  }
}
