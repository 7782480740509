@import '../styles/colors.scss';
@import '../styles/sizes.scss';

.alertes {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

.acknowlegeBtn {
  cursor: pointer;
  background: $tertiary1;
  border-radius: 8px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;

  /* Inside auto layout */

  flex: none;
  order: 4;
  flex-grow: 0;
  margin: 0px 8px;

  .base2 {
    color: $primary1;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 4px;
  }
}

.acknowlegeBtn.acknowledge {
  cursor: default;
  background: $neutral1;
  .base2 {
    color: $primary3;
  }
}

.alert-column-label.datetime {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 72px;
  text-align: left;
}

.alert-column-label-date {
  strong.caption1 {
    color: $neutral5;
    font-size: 13px;
    height: 16px;
    margin: 6px 0px;
  }
}

.alert-column-label-time {
  i.caption1 {
    color: $neutral4;
    font-size: 12px;
    height: 13px;
    margin: 0px;
  }
}

.alert-column-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 72px;
  text-align: left;
}

.alert-column-label-cell {
  strong.caption1 {
    color: $neutral5;
    font-size: 13px;
    height: 16px;
    margin: 6px 0px;
  }
}

.alert-column-label-cell {
  i.caption1 {
    color: $neutral4;
    font-size: 12px;
    height: 13px;
    margin: 0px;
  }
}

.alert-column-label.description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 72px;
  text-align: left;

  strong.caption1 {
    color: $neutral5;
    font-size: 13px;
    height: 16px;
    margin: 6px 0px;
  }
}

.severity-label {
  width: 56px;
  height: 20px;
  background-color: $neutral4;
  color: $neutral6;
  padding: 0px 6px;
  border-radius: 4px;
}

.severity-label.high {
  background-color: $tertiary4;
  .caption2bold {
    color: $primary4;
  }
}

.severity-label.medium {
  background-color: $tertiary5;
  .caption2bold {
    color: $primary5;
  }
}

.severity-label.low {
  background-color: $neutral3;
  .caption2bold {
    color: $neutral6;
  }
}
