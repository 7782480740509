@import './colors.scss';
@import './sizes.scss';

.table {
  margin-bottom: 10px;
  overflow: visible !important;
}

nav {
  margin-bottom: 100px;
}
.nbElements {
  color: $black900;
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 35px;
  margin-top: 30px;
}

.totalLine {
  font-weight: bold;
}
.table-link {
  color: $neutral4;
  text-decoration: underline solid;
  cursor: pointer;
}
.sub-table-link {
  color: $neutral5;
  text-decoration: underline solid;
  cursor: pointer;
}

.parent-table-row {
  color: $neutral4;
}

.table-link:hover {
  text-decoration: underline;
}
.table-container.right {
  min-width: calc(100% - 90px - 6vw);
}
.detail-container.right {
  min-width: calc(100% - 90px - 6vw);
}

.status {
  border-radius: 12px;
  padding: 7px 7px;
  white-space: break-spaces !important;
  overflow: hidden;
  text-overflow: initial !important;
  text-align: center;
  margin: auto;
}

// case "new":-> Gris
// case "chartered": -> Bleu
// case "accepted": -> Bleu
// case "delivering":-> Bleu
// case "validated":-> Vert
// case "active": -> Gris
// case "canceled": -> Rouge
// case "deleted":-> Rouge
// case "inactive":-> Gris
// case "partially_refused": Orange
// case "refused":Rouge
// case "pumping": -> Bleu
// case "supported":-> Bleu
// case "installed":-> Bleu
// case "ordered": -> Bleu
// case "confirmed_order":Vert
// case "accepted_order":-> Vert
// case "refused_order":-> Rouge

.validated,
.terminated,
.confirmed_order,
.accepted_order {
  background-color: $green50010;
  color: $green500;
}

.refused,
.canceled,
.deleted,
.refused_order {
  background-color: $error;
  color: $white;
}

.accepted,
.chartered,
.delivering,
.pumping,
.ordered,
.installed,
.supported {
  background-color: $primary50;
  color: $primary;
}

.new,
.inactive,
.active {
  background-color: $grey50010;
  color: $grey500;
}

.partially_refused {
  background-color: $orange;
  color: $white;
}

.searchbar {
  display: flex;
  flex-direction: row;
  margin-left: 7px;
  margin-top: 4px;

  .searchIcon {
    padding-top: 20px;
    margin-right: -34px;
    z-index: 1;
  }
  .search {
    border: 1px solid #e3e7f2;
    box-sizing: border-box;
    border-radius: 110px;
    width: 280px;
    padding-left: 40px;
    padding-right: 40px;
  }
  .search:focus {
    outline: none;
  }

  .clearSearch {
    width: 30px;
    left: -55px;
    position: relative;
    padding-top: 20px;
    cursor: pointer;
  }
}

.noResults {
  padding: 40px;
}

.filter {
  label {
    color: $grey500;
  }

  .filterTitle {
    margin-bottom: 10px;
    color: $grey900;
    font-weight: bold;
  }
}

.popup-filter {
  position: absolute;
  background-color: white;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.1);
  border-radius: 12px;

  padding: 14px;
  top: 98px;
  border: 1px solid #eaedf5;
  z-index: 13;
}

.ddahQf {
  width: fit-content;
  min-width: 77vw;
  overflow-y: auto !important;
}

.right-btn {
  position: absolute;
  right: 3vw;
}
