@import '../../../styles/colors.scss';
@import '../../../styles/sizes.scss';

.TruckZone {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

.TruckZone-column-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 172px;
  text-align: left;

  strong.captionG {
    color: $neutral5;
    font-size: 80px;
    height: 80px;
    margin: 16px 0px;
  }

  .captionG {
    color: $neutral5;
    font-size: 80px;
    height: 80px;
    margin: 16px 0px;
  }
}

.TruckZone-column-label.big {
  align-items: center;
  width: 100%;
  height: 200px;

  .captionG {
    font-size: 200px;
    height: 200px;
  }
}
