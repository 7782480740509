@import '../../styles/colors.scss';
@import '../../styles/sizes.scss';

.login {
  display: flex;
  flex-direction: row;
  flex: 1;

  .langSelector {
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 10px;
  }

  .leftImage {
    width: 50%;
    min-height: 100vh;
    content: '&nbsp';
    background: url('../../assets/dune-2.jpg');
    background-size: cover;
  }

  .right {
    width: 50%;
    padding-top: 10vh;
    padding-left: 10vh;
    h5 {
      margin-bottom: 0px;
    }
    input {
      width: 100%;
    }

    .p2 {
      margin-top: 10px;
    }
  }

  .addBtn {
    margin-top: 30px;
  }

  .title-center {
    text-align: center;
  }

  .content {
    margin: auto;
    width: 350px;
  }

  .button {
    margin-top: 40px;
  }

  .chooseWorkspaceExplanation {
    line-height: 24px;
    color: $grey500;
  }
}
