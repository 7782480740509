@import '../styles/colors.scss';
@import '../styles/sizes.scss';
@import '../styles/day-picker.scss';

.voyages {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

.tabHeader {
  display: flex;
  flex-direction: row;
  background-color: $neutral1;
  justify-content: space-between;
  align-items: center;
  padding: 15px 24px;
  max-height: 50px;
}

.tabHeaderTitle {
  align-items: center;
  flex-grow: 1;
  text-align: left;
}

.tabHeaderBtn {
  align-items: flex-start;
  flex-grow: 2;
  display: flex;
  justify-content: flex-end;
  max-height: 50px;
}

.buttonBar {
  button {
    width: 40% !important;
  }

  .row {
    justify-content: space-between;
  }

  .cancel {
    background-color: $grey900 !important;
    border-color: $grey900;
  }
  .close {
    margin-top: 20px;
    align-self: center;
    cursor: pointer;
    text-decoration: underline;
    :hover {
      font-weight: bold;
    }
  }
}

.table-report {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #fcfcfc;
  border-radius: 8px;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 8px 0px;
}

.table-report-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background: #fcfcfc;
  border-radius: 8px;
  flex-grow: 0;
  .table-report-header-title {
    margin-left: 24px;
    .base2 {
      color: $neutral7;
      font-size: 20px;
    }
  }
  .table-report-header-select {
    display: flex;
    margin-right: 24px;
    size: 139px;
    color: $neutral7;
    .svg {
      width: 36px;
      height: 36px;
      margin: 0px 10px;
    }
  }
}

.table-link {
  .svg {
    width: 20px;
    height: 20px;
    fill: $neutral4;
    stroke: $neutral4;
  }
}

.table-report-body {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #fcfcfc;
  border-radius: 8px;
  padding: 10px 0px;

  .table-report-body-tile {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 16px;

    border-radius: 12px;

    flex: none;
    order: 0;
    flex-grow: 1;
    margin: 0px 12px;
  }

  .table-report-body-tile.tertiary1 {
    background: $tertiary1;
  }

  .svgBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
    border-radius: 8px;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px 16px;

    .svg {
      fill: $neutral0;
    }
  }

  .dataBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
  }

  .dataBox-total {
    .base2 {
      color: $neutral5;
      font-size: 14px;
    }
  }

  .dataBox-data {
    min-height: 40px;
    .base2 {
      color: $neutral7;
      font-size: 40px;
    }
  }

  .svgBox.primary1 {
    background-color: $primary1;
  }

  .table-report-body-tile.tertiary2 {
    background: $tertiary2;
  }

  .svgBox.primary2 {
    background-color: $primary2;
  }

  .table-report-body-tile.tertiary3 {
    background: $tertiary3;
  }

  .svgBox.primary3 {
    background-color: $primary3;
  }
}

strong.caption2medium {
  color: $neutral5;
  font-size: 13px;
  font-weight: bolder;
}

i.caption2medium {
  color: $neutral4;
  font-size: 12px;
  font-weight: normal;
}

.firstColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px;
  width: 100%;

  .select-input {
    width: 100%;
  }

  .firstRow {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 16px;

    .caption2medium {
      text-align: left;
    }
  }
}
