@import '../../styles/colors.scss';
@import '../../styles/sizes.scss';

.tabFilter {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding: 0px;
  background-color: $neutral1;
  flex: none;
  order: 0;
  flex-grow: 1;
  height: 70px;
  border-radius: 8px 8px 0 0;
  margin-top: 20px;
}

.tabFilter__tab {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 24px;
  flex: none;
  order: 0;
  flex-grow: 1;
  box-shadow: inset 0px -3px 0px $neutral3;
}

.tab_active {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 0px;
  margin-right: 24px;

  background: neutral1;
  box-shadow: inset 0px -3px 0px $primary1;

  .base2 {
    color: $neutral7;
  }
}

.tab {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 0px;
  margin-right: 24px;
  background: #fcfcfc;
  box-shadow: inset 0px -3px 0px $neutral3;

  .base2 {
    color: $neutral4;
  }
}
