.dune-head {
  display: flex;
  flex-direction: row;
  width: 100%;
  &-left {
    display: flex;
    gap: 20px;
    width: 50%;
    align-items: center;
  }
  &-right {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: 50%;
  }
  &-main {
    width: 100%;
  }
}

.Sidebar {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
}
