@import '../../../styles/colors.scss';
@import '../../../styles/sizes.scss';

.list-content {
  display: flex;
  padding: 20px 70px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex-shrink: 0;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 24px 0px;

  border-radius: 8px;
  background: $neutral1;
}
