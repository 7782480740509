.panelInput {
  gap: 12px;
  flex: 1 0 0;
  width: 100%;
  text-align: left;

  display: flex;
  flex-direction: row !important;
  align-items: center;
  .input-prefix {
    width: 48px;
    height: 48px;
    align-items: center;
    text-align: center;
    background-color: #e4e5e7;
    border-radius: 8px 0px 0px 8px;
  }

  input {
    width: 100%;
    height: 48px;
    align-items: center;
    background: #f4f4f4;
    border-radius: 0px 8px 8px 0px !important;
  }

  .input {
    width: 100%;
    height: 48px;
    align-items: center;
    background: #f4f4f4;
    border-radius: 0px 8px 8px 0px !important;
  }
}

.panelInput.hidden {
  display: none;
}

.select-data {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  flex: 1 0;
  width: 100%;
  flex-grow: 1;
}

.select-data.checkbox_div {
  height: 96px;
}

.badge {
  display: flex;
  padding: 10px;
  border-radius: 8px;
  justify-content: center;
}
