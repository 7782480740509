@import './colors.scss';
@import './sizes.scss';

.popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10 10;

  .popup__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .popup-col {
      width: 100%;
    }

    .popup-col.right {
      width: 100%;
      padding: 0px 10px 0px 0px;
    }

    .popup-col.disabled {
      opacity: 0.3;
    }
  }

  .popup-col.same-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .popup__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
    flex: none;
    order: 0;
    flex-grow: 1;
    height: 40px;
  }

  .label {
    font-style: normal;
    font-weight: bold;
    font-size: $p3;
  }

  button {
    background: $primary;
    border-radius: 12px;
    width: 450px;
    border-color: $primary;
    color: white;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 50px;
    cursor: pointer;
  }

  input[type='text'],
  input[type='password'],
  input[type='number'],
  input[type='email'],
  input[type='datetime-local'],
  select,
  .input {
    width: 100%;
    height: 48px;
    border: 0px;
    border-radius: 8px;
    padding-left: 0px;
    color: $neutral5;
    background-color: $neutral2;
    font-size: 14px;
    border-bottom: 1px solid $neutral2;
    padding: 10px;
  }

  input[type='text']:focus,
  input[type='password']:focus,
  input[type='number']:focus,
  input[type='email']:focus,
  select:focus {
    border-bottom: 1px solid $grey900;
    outline: none;
  }

  select,
  .input {
    width: 400px;
    padding: 12px;
    margin-top: 12px;
    width: 450px;
  }

  .closeBtn {
    position: absolute;
  }
}
